// TODO:
// Possibly use https://codepen.io/zchee/pen/ogzvZZ for scroll instead of library
// Remove is-active class from navbar when page is going away
import VanillaScrollspy from 'vanillajs-scrollspy';

document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }

    const $navbarMenus = Array.prototype.slice.call(document.querySelectorAll('.navbar-menu'), 0);

    if ($navbarMenus.length > 0) {
        $navbarMenus.forEach(menu => {
            const $navbarMenuItems = Array.prototype.slice.call(menu.querySelectorAll('a'), 0);
            if ($navbarMenuItems.length > 0) {
                console.log('navbar items', $navbarMenuItems);
                $navbarMenuItems.forEach(item => {
                    item.addEventListener('click', () => {
                        menu.classList.remove('is-active');
                    })
                })
            }
        });
        
    }




    const navbar = document.querySelector('#navbarMenu');
    const navbarParent = document.querySelector('#nav-outer');
    const scrollspy = new VanillaScrollspy(navbar, 2000, 'easeOutSine', navbarParent);
    scrollspy.init();
});
